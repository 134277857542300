import { useEffect, useState } from "react";

function UseDeviceType() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkDeviceType = () => {
            setIsMobile(window.innerWidth <= 768); // Set your own threshold
        };

        checkDeviceType(); // Run on initial render

        // Add event listener to update on resize
        window.addEventListener("resize", checkDeviceType);

        // Clean up event listener on unmount
        return () => window.removeEventListener("resize", checkDeviceType);
    }, []);

    return isMobile;
}

export default UseDeviceType;
