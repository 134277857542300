import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import '../assets/styles/Carousel.css';
import useDeviceType from './UseDeviceType';

const HeroBanner = () => {
  const isMobile = useDeviceType();
  if(isMobile){
    var images = [
      'images/banner1m.jpg',
      'images/banner2m.jpg'
    ];
  }else{
    var images = [
      'images/banner1.jpg',
      'images/banner2.jpg'
    ];
  }
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
      };

    
    return (
        <Slider {...settings}>
                {images.map((image, index) => (
                   
                        <img src={image} alt={`Slide ${index}`} />
                ))}
           </Slider>
    );
};

export default HeroBanner;
