import React, { useState } from 'react';
import '../assets/styles/ProductSlider.css'
import Slider from "react-slick";
import useDeviceType from './UseDeviceType';
const ProductSlider = ({ title }) => {
  const isMobile = useDeviceType();
  const products = [
    {
      id: 1,
      image: 'product1.jpg',
      title: 'Product 1',
      description: 'This is a great product.',
    },
    {
      id: 2,
      image: 'product2.jpg',
      title: 'Product 2',
      description: 'This is another great product.',
    },
    {
      id: 3,
      image: 'product2.jpg',
      title: 'Product 2',
      description: 'This is another great product.',
    },
    // Add more products here
  ];
  
     const settings = {
        infinite: false, // Disable infinite scrolling to not show previous slide
        slidesToShow: isMobile?1.5:4.5,  // Show 1.5 slides
        slidesToScroll: 1,
        centerMode: false,  // Disable centering to avoid showing the previous slide
        focusOnSelect: true, // Allows selecting slides on click
        responsive: [
            {
                breakpoint: 768, // For mobile devices
                settings: {
                    slidesToShow: 1.1, // Show more of the next slide
                    centerMode: false,
                },
            },
        ],
    };
  return (
    <div className="slider">
      <h2 className="centered-heading">{title}</h2>
      <div className="productSlider">
      <Slider {...settings}>
        <div className="slider-wrapper">
          <div className="productBox">
            <div className="productImage">
              <a><img src="/images/productimg1.jpg"></img></a>
            </div>
            <div className="productTitle">Chicken Adraki Seekh Kebab1</div>
            <div className='productDesc'>Savor the authentic taste of our Chicken Adraki Seekh K...</div>
            <div className='productPrice'>₹206.00</div>
            <button>Buy Now</button>
          </div>
        </div>
        <div className="slider-wrapper">
          <div className="productBox">
            <div className="productImage">
              <a><img src="/images/productimg1.jpg"></img></a>
            </div>
            <div className="productTitle">Chicken Adraki Seekh Kebab2</div>
            <div className='productDesc'>Savor the authentic taste of our Chicken Adraki Seekh K...</div>
            <div className='productPrice'>₹206.00</div>
            <button>Buy Now</button>
          </div>
        </div>
        <div className="slider-wrapper">
          <div className="productBox">
            <div className="productImage">
              <a><img src="/images/productimg1.jpg"></img></a>
            </div>
            <div className="productTitle">Chicken Adraki Seekh Kebab3</div>
            <div className='productDesc'>Savor the authentic taste of our Chicken Adraki Seekh K...</div>
            <div className='productPrice'>₹206.00</div>
            <button>Buy Now</button>
          </div>
        </div>
        <div className="slider-wrapper">
          <div className="productBox">
            <div className="productImage">
              <a><img src="/images/productimg1.jpg"></img></a>
            </div>
            <div className="productTitle">Chicken Adraki Seekh Kebab1</div>
            <div className='productDesc'>Savor the authentic taste of our Chicken Adraki Seekh K...</div>
            <div className='productPrice'>₹206.00</div>
            <button>Buy Now</button>
          </div>
        </div>
        <div className="slider-wrapper">
          <div className="productBox">
            <div className="productImage">
              <a><img src="/images/productimg1.jpg"></img></a>
            </div>
            <div className="productTitle">Chicken Adraki Seekh Kebab1</div>
            <div className='productDesc'>Savor the authentic taste of our Chicken Adraki Seekh K...</div>
            <div className='productPrice'>₹206.00</div>
            <button>Buy Now</button>
          </div>
        </div>


      </Slider>  </div></div>);
};

export default ProductSlider;
