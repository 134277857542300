import React from 'react';
import HeaderContainer from '../components/HeaderContainer';
import FooterContainer from '../components/FooterContainer';
import HeaderCategory from '../components/HeaderCategory';
import Checkout from './Checkout';

const CheckoutPage = () => {


  return (
    <>
        <HeaderContainer />
        <HeaderCategory />
        <Checkout  />
        <FooterContainer />
    </>
  );
};

export default CheckoutPage;
