import React from 'react';
import HeaderContainer from '../components/HeaderContainer';
import FooterContainer from '../components/FooterContainer';
import HeaderCategory from '../components/HeaderCategory';
import '../assets/styles/ProductDetails.css'
import ProductList from './ProductList';
const ProductListPage = () => {


  return (
    <>
        <HeaderContainer />
        <HeaderCategory />
        <ProductList  />
        <FooterContainer />
    </>
  );
};

export default ProductListPage;
