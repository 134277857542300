import { AiOutlineHeart } from "react-icons/ai";
import { BiShoppingBag } from "react-icons/bi";
import ReactImageGallery from "react-image-gallery";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import '../assets/styles/ProductDetails.css'
const ProductDetail = () => {
  const productDetailItem = {
    images: [
      {
        original:
          "/images/chicken_sith_skin.jpeg",
        thumbnail:
          "/images/chicken_sith_skin.jpeg",
      },
      {
        original:
          "/images/chicken2.jpg",
        thumbnail:
          "/images/chicken2.jpg",
      },
     
    ],
    title: "Chicken Italian Garlic Sausage",
    reviews: "150",
    netgram: "200g",
    pieces: "5 Pieces",
    serves: "2-3",
    price: 450,
    previousPrice: 599,
    description:
      "Indulge in the rich flavors of our Chicken Italian Garlic Sausage, made from premium chicken blended with garlic and Italian herbs. These sausages are ideal for grilling, frying, or baking and provide a tasty, low-fat, high-protein option for any meal. Enjoy them in a variety of dishes for a burst of Italian flavor.",
    size: [],
    color: [],
  };
  const plusMinuceButton =
    "flex h-8 w-8 cursor-pointer items-center justify-center border duration-100 hover:bg-neutral-100 focus:ring-2 focus:ring-gray-500 active:ring-2 active:ring-gray-500";
  return (
    <section className="container flex-grow mx-auto max-w-[1200px] border-b py-5 lg:grid lg:grid-cols-2 lg:py-10">
      {/* image gallery */}
      <div className="container mx-auto px-4">
        <ReactImageGallery
          showBullets={false}
          showFullscreenButton={false}
          showPlayButton={false}
          items={productDetailItem.images}
        />

        {/* /image gallery  */}
      </div>
      {/* description  */}

      <div className="mx-auto px-5 lg:px-5">
        <h2 className="pt-3 text-2xl font-bold lg:pt-0">
          {productDetailItem.title}
        </h2>
        <div className="mt-1">
          <div className="flex items-center">
            <Rater
              style={{ fontSize: "20px" }}
              total={5}
              interactive={false}
              rating={3.5}
            />

            <p className="ml-3 text-sm text-gray-400">
              ({productDetailItem.reviews})
            </p>
          </div>
        </div>
        <p className="mt-5 font-bold">
          Net:{" "}
          <span className="text-green-600">{productDetailItem.netgram}</span>
        </p>
        <p className="font-bold">
          No of pieces: <span className="font-normal">{productDetailItem.pieces}</span>
        </p>
        <p className="font-bold">
          Serves:{" "}
          <span className="font-normal">{productDetailItem.serves}</span>
        </p>
        
        <p className="mt-4 text-4xl font-bold text-violet-900">
          ₹{productDetailItem.price}{" "}
          <span className="text-xs text-gray-400 line-through">
            ₹{productDetailItem.previousPrice}
          </span>
        </p>
        <p className="pt-5 text-sm leading-5 text-gray-500">
          {productDetailItem.description}
        </p>
        {productDetailItem.size.length > 0 && (
        <div className="mt-6">
          <p className="pb-2 text-xs text-gray-500">Size</p>
          <div className="flex gap-1">
            {productDetailItem.size.map((x, index) => {
              return (
                <div
                  key={index}
                  className="flex h-8 w-8 cursor-pointer items-center justify-center border duration-100 hover:bg-neutral-100 focus:ring-2 focus:ring-gray-500 active:ring-2 active:ring-gray-500"
                >
                  {x}
                </div>
              );
            })}
          </div>
        </div>
        )}
        {productDetailItem.color.length > 0 && (
        <div className="mt-6">
          <p className="pb-2 text-xs text-gray-500">Color</p>
          <div className="flex gap-1">
            {productDetailItem.color.map((x, index) => {
              return (
                <div
                  key={index}
                  className={`h-8 w-8 cursor-pointer border border-white bg-${x}-600 focus:ring-2 focus:ring-${x}-500 active:ring-2 active:ring-${x}-500`}
                />
              );
            })}
          </div>
        </div>
        )}
        <div className="mt-6">
          <p className="pb-2 text-xs text-gray-500">Quantity</p>
          <div className="flex">
            <button className={`${plusMinuceButton}`}>−</button>
            <div className="flex h-8 w-8 cursor-text items-center justify-center border-t border-b active:ring-gray-500">
              1
            </div>
            <button className={`${plusMinuceButton}`}> +</button>
          </div>
        </div>
        <div className="mt-7 flex flex-row items-center gap-6">
          <button className="flex h-12  items-center justify-center bg-red-900 text-white duration-100 hover:bg-red-800">
            <BiShoppingBag className="mx-2" />
            Add to cart
          </button>
         
        </div>
      </div>
    </section>
  );
};

export default ProductDetail;