import React, { useState , useEffect, useRef} from "react";
import Slider from "react-slick";
import "../assets/styles/Category.css";

const HeaderCategory = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [categories, setCategory] = useState([]);
  const [error, setError] = useState(null);
  const dropdownRef = useRef(null);
  
  useEffect(() => {
    // Fetch data from the backend
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/productCategory/getAll`, {
          method: 'POST', // Ensure this is set to POST
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            key1: 'value1', // Example data being sent in the request
          }),
        });
    
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
    
        const data = await response.json();
        setCategory(data);
      } catch (error) {
        console.error('Fetch error:', error);
        setError(error.message);
      }
    };
     

    fetchCategories();
  }, []);


  // Toggle dropdown on click
  const toggleDropdown = (category) => {
    setOpenDropdown((prevCategory) => 
      prevCategory === category ? null : category
    );
  };
   // Handle clicks outside the dropdown to close it
   useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // Slider settings
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6, // Adjust based on how many items you want to display
    slidesToScroll: 2, // Adjust based on scroll preference
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="category-slider">
    <Slider {...settings}>
      {categories.map((cat, index) => (
        <div
          className="items"
          key={index}
          ref={dropdownRef}
        >
          <div className="catImg">
            <img src={cat.image} alt={cat.name} />
          </div>
          <a href="#" onClick={() => toggleDropdown(cat)}>
            {cat.name}
            <span className="dropdown-icon">
              {openDropdown === cat ? "▲" : "▼"}
            </span>
          </a>
          {openDropdown === cat && (
            <div className="dropdown-content" onMouseLeave={() => setOpenDropdown(null)}>
              <a href="#">Salmon</a>
              <a href="#">Tuna</a>
            </div>
          )}
        </div>
      ))}
    </Slider>
  </div>
  );
};

export default HeaderCategory;
