import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HeroBanner from '../components/HeroBanner';
import ProductSlider from '../components/ProductSlider';
import HeaderContainer from '../components/HeaderContainer';
import FooterContainer from '../components/FooterContainer';
import HeaderCategory from '../components/HeaderCategory';

const HomePage = () => {


  return (
    <>
        <HeaderContainer />
        <HeaderCategory />
        <HeroBanner  />
        <ProductSlider title="In the Spotlight"/>
        <FooterContainer />
    </>
  );
};

export default HomePage;
