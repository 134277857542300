import React, { useState, useEffect } from 'react';
import '../assets/styles/HeaderContainer.css'
import { BiShoppingBag } from "react-icons/bi";
import { FaShoppingCart } from "react-icons/fa";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
const HeaderContainer = ({ images }) => {

    const cartItemCount=2

    return (
        <header className="bg-white-800 text-white py-4 px-6 flex items-center justify-between">
        {/* Logo */}
        <div className="flex items-center">
          <Link to="/" className="flex items-center logobox">
            <img
              src="/images/abc.png" // Replace with your logo URL
              alt="Logo" className="h-10"
            />
          </Link>
        </div>
  
        {/* Navigation and Cart Icon */}
        <nav className="flex items-center gap-4">
  
          {/* Cart Icon */}
          <Link to="/" className="relative flex items-center text-xl hover:text-gray-300">
            <FaShoppingCart className="text-black" />
            
            {cartItemCount > 0 && (
            <span className="absolute -top-2 -right-2 h-5 w-5 rounded-full bg-red-600 text-white flex items-center justify-center text-xs font-bold">
              {cartItemCount}
            </span>
          )}
          </Link>
        </nav>
      </header>
    );
};

export default HeaderContainer;
