import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ProductPage from './components/ProductPage';
import HomePage from './components/HomePage';
import ProductListPage from './components/ProductListPage';
import CheckoutPage from './components/CheckoutPage';

const App = () => {


  return (
     <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/product/:productId" element={<ProductPage />} />
        <Route path="/products" element={<ProductListPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
      </Routes>
    </Router>
  );
};

export default App;
